/* eslint-disable no-case-declarations */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import BoxTopPanel from './components/topPanel/main';
import BoxLeftPanel from './components/leftPanel/main';
import thunkMiddleware from 'redux-thunk';
import moment from 'moment';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import AppStore from './reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { runAction } from './actions';
import { loadTheme, createTheme, getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useTranslation } from 'react-i18next';
import './i18n_init';
import { ClipLoader } from 'react-spinners';
import MainContent from './components/contentBox/main';
import DiagramParamsPanel from './components/diagram_params_panel/main';
import hostForLocation from './hostnames/hostname';
import { convertWhiteLabelColor } from './tools/colors';
import styled from 'styled-components/macro';
import CompleteTablesPanel from './components/xlsx/complete_tables';
import MainPlan from './components/plan/main';
import Modal from './components/modal/modal';
import OverlaySpinner from './components/overlay_spinner/overlay_spinner';
import Alert from './components/alert/alert';
import OutdoorSpaces from './components/outdoor-spaces';
import AdCampaigns from './components/ad_campaigns';
import LogOverlay from './components/log_overlay/log_overlay';
import Cloud from './cloud';
import PdfMainContent from './components/Diagrams/components/pdf_branch/pdf_main_content';
import { getAdCampaigns, getSegments } from './api/api';

import MainSettingsControlPanel from './oldComponenta/settingsControlPage/main';
import { useHotkeys } from 'react-hotkeys-hook';
import Cookies from 'universal-cookie';

import './style.scss';
import Tools from './tools/tools';
import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { initializeIcons } from '@uifabric/icons';
import refresh from './components/Diagrams/tools/refresh';
import MainPdf from "./components/pdf";
import ModalPre from "./components/pre_campaign/components/modal";
initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/');
const cookie = new Cookies();

const GA_ID = 'UA-152881166-1';
const CHECK_VERSION_INTERVAL = 60000;
const CHECK_AD_CAMPAIGNS_INTERVAL = 1000 * 60 * 5;
const RESET_AD_CAMPAIGNS_TIME = 1000 * 60 * 60;

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    font-family: ProximaNova;
`;

export const SpinnerContainer2 = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    display: grid;
    justify-content: center;
`;

export const SpinnerContainerTemp = styled.div`
    margin: auto 0;
`;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(AppStore, /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware)));
const history = syncHistoryWithStore(createBrowserHistory(), store);

// history.listen((location) => {
//     if (location) {
//         const arr = location.pathname.split('/');
//         const pageAddress = arr.reduce((acc, item, i) => {
//             return i > 3 ? acc : `${acc}${item}/`;
//         }, '');
//         // console.log(pageAddress);
//         address = pageAddress;

//         ReactGA.pageview(pageAddress); // Record a pageview for the given page
//         ReactGA.set({ page: location.pathname }); // Update the user's current page
//     }
// });

const Main = React.memo(({ ...props }) => {
    const paramsSet = Tools.useGetParamSet();
    const { t, i18n } = useTranslation();
    const [allCatalogsReady, setAllCatalogsReady] = useState(false);
    const [engineeringCount, setEngineeringCount] = useState(0);
    const [address, set_address] = useState('');
    const [adCampInterval, setAdCampInterval] = useState(null);
    const dispatch = useDispatch();

    const { token, userName, white_label_settings, showOverlaySpinner, project, owner, options, statusSucces, errorStatus } = useSelector((state) => state.LoginApp);
    const { logOverlay } = useSelector((state) => state.Diagrams);
    const { backend } = useSelector((state) => state.EngineeringMenu);
    const {
        lang,
        cabinetMode,
        reqInput,
        status,
        statusLeftPanel,
        sectionsByKeyName,
        isPermanentCloudDataReceived,
        permanentCloudData,
    } = useSelector((state) => state.TopPanel);
    const {
        isShowPrecampModal
    } = useSelector((state) => state.PreCampaigns);
    const {
        
        isLoadData
    } = useSelector(
        (state) => state.PreCampaigns
    );
    const { objLocationSelect, locations } = useSelector((state) => state.PanelLocationApp);
    const { selected, selected2 } = useSelector((state) => state.CalendarApp);
    const { campaignsToShow, singleSelection, multiSelection, isAdCampShown, showAdSpacesMapPanel } = useSelector(
        (state) => state.AdCampaigns
    );
    const { segmentsToShow } = useSelector((state) => state.CustomControls);
    const { statusShowSettingsPanel, isShowSpinerPlan } = useSelector((state) => state.RadiusApp);
    const { statusShowPlanPanel } = useSelector((state) => state.PlanApp);
    const { isCompleteTablesShown } = useSelector((state) => state.XLSX);
    const { isShowModalPdf } = useSelector((state) => state.Pdf);

    const { isMapExpanded } = useSelector((state) => state.OutdoorSpaces);

    const onClickReloadDiagram = (...args) => {
        refresh(dispatch, token, ...args, null, true);
    };

    const {
        modal,
        sharedAlert,
        Source,
        customInputParamsValues,
        input_parameter_list,
        isCloudDataReceived,
        dataViewsByGroupID,
    } = useSelector((state) => state.ReportParametersManager);
    const { ctg, src } = Source;
    const { dataObjSetsById } = ctg;
    const { ad_campaign_id, ad_campaign_ids, project_id } = customInputParamsValues;
    const { project_locations, analytics_report_objects, ad_campaigns, data_view_list } = src;

    const _setAddr = (addr, pageAddress) => {
        if (addr !== address) {
            
            set_address(addr);
            ReactGA.pageview(pageAddress); // Record a pageview for the given page
            ReactGA.set({ page: addr }); // Update the user's current page
        }
    };

    history.listen((location) => {
        if (location) {
            const arr = location.pathname.split('/');
            const pageAddress = arr.reduce((acc, item, i) => {
                return i > 3 ? acc : `${acc}${item}/`;
            }, '');

            
            _setAddr(location.pathname, pageAddress);
        }
    });

    useHotkeys('shift+d+o', () => setEngineeringCount((prev) => prev + 1));

    useEffect(() => {
        if (!allCatalogsReady) return;
        const addressArr = address.split('/').filter((string) => string !== '');
        
        if (addressArr[0] !== lang || addressArr[1] !== status || addressArr[2] !== statusLeftPanel) {
            fillAddressFromNav(addressArr);
        }
    }, [address]);

    useEffect(() => {
        if (options && options.length !== 0) {
            setInitialPermanentCloudData(options);
        }
    }, [options]);

    useEffect(() => {
        if (errorStatus === 'Error') {
            cookie.remove('xtoken', {path: '/'})
            document.location.reload();
        }

    }, [errorStatus]);

    useEffect(() => {
        if (!token || !allCatalogsReady) return;

        const newAddress = `/${lang}/${status}/${statusLeftPanel}${cabinetMode}/`;
        // console.log('!!!!!!!!!!!!',newAddress);
        
        if (address !== newAddress && !newAddress.includes('-pdf')) {
            history.push(newAddress);
        }
    }, [lang, status, statusLeftPanel, cabinetMode]);

    useEffect(() => {
        if (engineeringCount > 0) {
            openEngineeringModal(backend);
        }
    }, [engineeringCount]);

    useEffect(() => {
        const back = JSON.parse(localStorage.getItem('backend'));
        if (back !== null) {
            fillBackend(back);
        }
    }, []);
    useEffect(() => {
        if (Object.keys(backend).length !== 0) {
            localStorage.setItem('backend', JSON.stringify(backend));
        }
    }, [backend]);

    useEffect(() => {
        if (project !== undefined && owner !== undefined) {
            fillOwnerAndProject(owner, project);
        }
    }, [project, owner]);

    useEffect(() => {
        if (!ad_campaigns || ad_campaigns.length === 0 || paramsSet.custom.length === 0) {
            return;
        } else {
            var id = ad_campaigns[0].id;

            if (customInputParamsValues.ad_campaign_id) {
                if (customInputParamsValues.ad_campaign_id !== id) {
                    id = customInputParamsValues.ad_campaign_id
                }
            }
            if (singleSelection === null && multiSelection.length === 0) {
                dispatch(runAction('onSelectClick_AdCampaigns', { args: { id, rowIndex: 0 }, paramsSet }));
                if (!isAdCampShown) {

                    dispatch(runAction('changeAdCampaignIds_RPM', { ad_campaign_id: id, ad_campaign_ids: [id] }));
                }
            }
        }
    }, [ad_campaigns, paramsSet]);

    useEffect(() => {
        // console.log(ad_campaign_id, singleSelection);
        if (singleSelection && ad_campaign_id !== singleSelection.id) {
            dispatch(runAction('onSelectClick_AdCampaigns', { args: { id: ad_campaign_id, rowIndex: 0 }, paramsSet }));
        }
    }, [ad_campaign_id, ad_campaign_ids, paramsSet]);

    useEffect(() => {
        fetch('/static/version.txt', { cache: 'no-cache' })
            .then((res) => (res.ok ? res.text() : Promise.reject(res)))
            .then((text) => {
                cookie.set('version', text.trim(), { path: '/' });
            })
            .catch(() => console.warn('Net Error'));

        const versionInterval = setInterval(checkVersion, CHECK_VERSION_INTERVAL);
        return () => {
            clearInterval(versionInterval);
        };
    }, []);

    useEffect(() => {
        let language = window.navigator
            ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
            : 'ru';
        language = language.substr(0, 2).toLowerCase();
        hostForLocation.getLocationForHost(document.location.host, backend);
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', language)
        // hostForLocation.getLocationForHost(document.location.host, cookie.get('analytics_backend'));
        let { pathname } = document.location;

        if (pathname.split('/').length <= 2) {
            let arrTemp = pathname.split('/');
            arrTemp[1] = 'en';
            pathname = arrTemp.join('/');
            getLocaleLanguage('en-EN');
        } else {
            getLocaleLanguage(language === 'ru' ? 'ru-RU' : 'en-EN');
        }

        getLocation(pathname);
    }, [document.location.host, backend]);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        let token;
        let searchParams = new window.URLSearchParams(document.location.search);
        const shared_cloud_hash = searchParams.get('shared_cloud_hash');

        if (shared_cloud_hash) {
            fillSharedCloudHash(decodeURIComponent(shared_cloud_hash));
        }

        if (searchParams.get('token')) {
            token = searchParams.get('token');
            cookie.set('xtoken', token, { path: '/' });
        } else {
            token = cookie.get('xtoken');
        }

        if (token) {
            loginByToken(token);
            getToken(token);
        } else {
            window.location.replace(
                `${hostForLocation.getHostName().ssoWifiZoneMe}/accounts/login?next=` +
                    window.location.href.split('?')[0] +
                    '&' +
                    window.location.href.split('?')[1],
                '_blank'
            );
        }

        // getLoadLogin();
    }, []);

    useEffect(() => {
        if (!token) return;
        getDetailInputParams();
        getObjectsAndLocations(token);
        getAvailableOwners(token);
        getAvailableProjects(token);
        getOutdoorAdSpaces(token);
        getAdSpacesTypes(token);
        getPolygonGroups(token);
        getDataViewList();
    }, [token]);

    useEffect(() => {
        if (data_view_list && permanentCloudData && input_parameter_list.length !== 0) {
            getReportList(data_view_list, permanentCloudData, sectionsByKeyName);
        }
    }, [input_parameter_list, permanentCloudData, data_view_list, sectionsByKeyName]);

    useEffect(() => {
        clearInterval(adCampInterval);
        if (!token) return;
        getAdCampaigns(token, dispatch, campaignsToShow, project_id);

        const interval = setInterval(() => getAdCampaigns(token, dispatch, campaignsToShow, project_id), CHECK_AD_CAMPAIGNS_INTERVAL);
        setAdCampInterval(() => interval);
        restartAdCampTimer();
        return () => {
            clearInterval(adCampInterval);
        };
    }, [token, project_id, campaignsToShow]);

    useEffect(() => {
        // clearInterval(adCampInterval);
        if (!token) return;
        getSegments(token, dispatch, segmentsToShow);

        // const interval = setInterval(() => getAdCampaigns(token, dispatch, segmentsToShow, project_id), CHECK_AD_CAMPAIGNS_INTERVAL);
        // setAdCampInterval(() => interval);
        // restartAdCampTimer();
        // return () => {
        //     clearInterval(adCampInterval);
        // };
    }, [token, segmentsToShow]);

    useEffect(() => {
        if (customInputParamsValues.pl_id && token) {
            getPLId(customInputParamsValues.pl_id, token);
        }
    }, [customInputParamsValues.pl_id, token]);

    useEffect(() => {
        if (!project_locations) return;
        for (let i = 0; i < project_locations.length; i++) {
            if (objLocationSelect[project_locations[i].id] !== undefined) {
                if (project_locations[i].detail === undefined) {
                    if (Tools.compare2(objLocationSelect, objLocationSelect) === false) {
                        onReqiuestObjecta(project_locations[i].id, project_locations, token);
                    }
                }
            }
        }
    }, [project_locations, objLocationSelect]);

    useEffect(() => {
        if (Object.keys(ctg).every((key) => ctg[key] !== undefined) && allCatalogsReady === false && input_parameter_list.length !== 0) {
            setAllCatalogsReady(true);
        }
    }, [ctg]);

    // useEffect(() => {
    //     if (!token) return;
    //     getAdCampaigns(token, dispatch, campaignsToShow, project_id);
    // }, [campaignsToShow]);

    useEffect(() => {
        if (userName !== null) {
            ReactGA.initialize(GA_ID, {
                gaOptions: {
                    userId: userName,
                },
            });
        }
    }, [userName]);

    useEffect(() => {
        if (white_label_settings === null || white_label_settings.page_title === undefined) {
            document.title = 'Analytics';
        } else {
            document.title = white_label_settings.page_title;
        }

        if (white_label_settings !== null && white_label_settings.header_color !== null) {
            loadTheme(
                createTheme({
                    defaultFontStyle: { fontFamily: 'ProximaNova, sans-serif', fontWeight: 400, lineHeight: '120%' },
                    fonts: {
                        medium: { fontSize: 15 },
                    },
                    palette: {
                        neutralLighterAlt: '#f8f8f8',
                        neutralLighter: '#f4f4f4',
                        neutralLight: '#eaeaea',
                        neutralQuaternaryAlt: '#dadada',
                        neutralQuaternary: '#d0d0d0',
                        neutralTertiaryAlt: '#c8c8c8',
                        neutralTertiary: '#b2b2b2',
                        neutralSecondary: '#999999',
                        neutralPrimaryAlt: '#808080',
                        neutralPrimary: '#1c1c1c',
                        neutralDark: '#4e4e4e',
                        black: '#353535',
                        white: '#ffffff',
                        ...convertWhiteLabelColor(white_label_settings.header_color),
                    },
                })
            );
        }
    }, [white_label_settings]);

    useEffect(() => {
        if (status) {
            fullScreenExit();
        }
    }, [status]);

    useEffect(() => {
        if (!analytics_report_objects || locations.length === 0) return;
        getRequestReportings({
            locations,
            analytics_report_objects,
        });
    }, [locations, analytics_report_objects]);

    const openEngineeringModal = (backend) => {
        toggleModal({
            show: true,
            modalType: 'engineering_modal',
            modalTitle: t('Инженерное меню'),
            modalText: '',
            height: 'auto',
            width: '60%',
            modalArgs: {
                name: '',
                id: '',
                props: { backend },
            },
        });
    };

    const checkVersion = () => {
        const cookieVer = cookie.get('version');
        fetch('/static/version.txt', { cache: 'no-cache' })
            .then((res) => (res.ok ? res.text() : Promise.reject(res)))
            .then((text) => {
                if (cookieVer.trim() !== text.trim()) {
                    console.log('>>>>><>>>>',cookieVer,  text)
                    toggleModal({
                        show: true,
                        modalType: 'refresh',
                        modalTitle: t('Требуется перезагрузка страницы!'),
                        modalText: t(
                            'Доступна новая версия web-приложения Shopster Client Service. Пожалуйста, перезагрузите страницу для корректной работы.'
                        ),
                        modalArgs: {
                            name: '',
                            id: '',
                            clickableOverlay: false,
                        },
                        buttons: [
                            {
                                text: t('Отмена'),
                                width: '100px',
                                action: null,
                            },
                            {
                                type: 'primary',
                                text: 'OK',
                                width: '100px',
                                action: {
                                    name: 'fullRefresh',
                                },
                            },
                        ],
                    });
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 55000);
                } else {
                    // console.log('Versions are equal');
                }
            })
            .catch(() => console.warn('Net Error'));
    };

    const _onModalButtonClick = (action, args) => {
        if (!action || action === null) {
            toggleModal({ show: false });
            return;
        }
        switch (action.name) {
            case 'renameObjSet':
                const objects = [...dataObjSetsById[args.id].bound_dataobjs];
                onChangeObjSet(args.id, args.name, objects, token);
                break;
            case 'fullRefresh':
                window.location.reload(false);
                break;
            case 'showAdSpacesMapPanel':
                toggleAdSpacesMapPanel(true);
                break;
            case 'saveCustomParams':
                changeCustomParams(args);
                break;
            case 'addDiagram':
                addDiagramToC_D(args);
                break;
            case 'resetMyDashboard':
                resetMyDashboard();
                break;
            case 'makeNewObjSet':
                createDataObjSet(token, args.name, args.objects);
                break;
            case 'createNewSubsection':
                createNewSubsection(args.subsectionName);
                break;
            case 'renameSubsection':
                renameSubsection(args);
                break;
            case 'deleteSubsection':
                deleteSubsection(args.subsectionID);
                break;
            case 'deleteGroup':
                deleteGroup(args);
                break;
            case 'createNewGroup':
                createNewGroup(args);
                break;
            case 'renameGroup':
                renameGroup(args);
                break;
            case 'createNewAdCampaign':
                createNewAdCampaign(token, args);
                break;
            case 'createNewSegment':
                createNewSegment(token, args);
                break;
            case 'deleteAdCampaign':
                deleteAdCampaign(token, action.params);
                break;
            case 'changeBackendUrls':
                fillBackend(args);
                break;

            default:
                toggleModal({ show: false });
                break;
        }
    };

    const _onClickGlobalHolstForMapsHideAnaliticsPanel = (e) => {
        getStatusSelectPolygon(false);
    };

    // ------------ DISPATCH ------------

    const createNewSubsection = (subsectionName) => {
        dispatch(runAction('createNewSubsection_TopPanel', { subsectionName }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };

    const renameSubsection = (args) => {
        dispatch(runAction('renameSubsection_TopPanel', { ...args }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };

    const deleteSubsection = (subsectionID) => {
        dispatch(runAction('deleteSubsection_TopPanel', { subsectionID }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };

    const deleteGroup = (args) => {
        dispatch(runAction('deleteGroup_TopPanel', { ...args }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };

    const createNewGroup = (args) => {
        dispatch(runAction('createNewGroup_TopPanel', { ...args }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };

    const renameGroup = (args) => {
        dispatch(runAction('renameGroup_TopPanel', { ...args }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };

    const setInitialPermanentCloudData = (options) => {
        dispatch(runAction('setInitialPermanentCloudData_TopPanel', { options }));
    };

    const restartAdCampTimer = () => {
        setTimeout(() => dispatch(runAction('onShowReset_AdCampaigns', {})), RESET_AD_CAMPAIGNS_TIME);
    };

    const deleteAdCampaign = (token, params) => {
        const { selection, hoverRow } = params;
        const id = hoverRow.id;

        if (selection !== null && id === selection.id) {
            dispatch(runAction('onSelectClick_AdCampaigns', { args: null, paramsSet }));
        }

        const url = `${hostForLocation.getHostName().export}/adapp/campaigns/deactivate/${id}/`;

        const headers = {
            'x-token': token,
        };
        const options = {
            method: 'POST',
            headers,
        };
        fetch(url, options)
            .then((res) => (res.ok ? res : Promise.reject(res)))
            .then(() => toggleSharedAlert({ show: true, position: 'bottom', type: 'success', message: t('Рекламная кампания удалена') }))
            .then(() => getAdCampaigns(token, dispatch, campaignsToShow, project_id))
            .catch((err) =>
                toggleSharedAlert({
                    show: true,
                    position: 'bottom',
                    type: 'error',
                    message: t('Возникла ошибка при удалении рекламной кампании'),
                })
            );
    };

    const getLocation = (phathName) => {
        if (phathName !== '/') {
            dispatch(runAction('getLocation', { phathName }));
            dispatch(runAction('getLocationsIdForDate', { phathName }));
            dispatch(runAction('getLocationForInputParam', { phathName }));
        }
    };
    const changeCustomParams = (params) => {
        dispatch(runAction('changeCustomParams_RPM', { params }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };
    const addDiagramToC_D = (args) => {
        dispatch(runAction('addDiagramToC_D_TopPanel', { ...args }));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };
    const resetMyDashboard = () => {
        dispatch(runAction('resetMyDashboard_TopPanel', {}));
        dispatch(runAction('toggleModal', { args: { show: false } }));
    };
    const toggleAdSpacesMapPanel = (show) => {
        dispatch(runAction('toggleAdSpacesMapPanel_AdCampaigns', { show }));
    };
    const fillAddressFromNav = (addressArr) => {
        dispatch(runAction('fillAddressFromNav_TopPanelHotspotApp', { addressArr }));
    };
    const onReqiuestObjecta = (status, locations, token) => {
        dispatch(runAction('getIdPL', { id: status }));
    };
    const fillOwnerAndProject = (owner, project) => {
        dispatch(runAction('fillOwnerAndProject_RPM', { owner, project }));
    };
    const getPLId = (id, token) => {
        let url = `${hostForLocation.getHostName().admin}/project_locations/${id}/`;
        var headers = {
            'x-token': token,
        };
        dispatch(runAction('getRequestPlanSets_MapsReducer', { token }));
        dispatch(runAction('getPLId', { url, headers }));
    };
    const toggleModal = (args) => {
        dispatch(runAction('toggleModal', { args }));
    };

    const getDetailInputParams = () => {
        dispatch(
            runAction('getDetailInputParams', {
                url: `${hostForLocation.getHostName().analytics}/data_structure/input_parameter_list`,
            })
        );
    };

    const getDataViewList = () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
            redirect: 'follow',
        };

        fetch(`${hostForLocation.getHostName().analytics}/content_structure/data_view_list/`, options)
            .then((res) => {
                if (res.ok) {
                    return res;
                } else {
                    return Promise.reject(res);
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                dispatch(runAction('getDataViewList', { json }));
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const getReportList = (data_view_list, permanentCloudData, sectionsByKeyName) => {
        dispatch(runAction('setReportList', { data_view_list, permanentCloudData, sectionsByKeyName }));
    };

    const getLocaleLanguage = (lang) => {
        dispatch(runAction('getLocaleLanguage', { lang }));
    };
    const fillSharedCloudHash = (shared_cloud_hash) => {
        dispatch(runAction('fillSharedCloudHash', { shared_cloud_hash }));
    };
    const loginByToken = (token) => {
        let url = `${hostForLocation.getHostName().admin}/accounts/current`;
        let headers = {
            token,
        };
        let headers2 = {
            'x-token': token,
        };
        dispatch(runAction('loginByToken', { url, headers, headers2 }));
    };
    const getToken = (token) => {
        dispatch(runAction('getToken', { token }));
    };
    const getObjectsAndLocations = (token) => {
        let locationsURL = `${hostForLocation.getHostName().admin}/project_locations/`;
        let objectsURL = `${hostForLocation.getHostName().admin}/analytics_structure/fast_analytics_report_objects/`;
        let objSetsURL = `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/`;
        let headers = {
            'x-token': token,
            'Content-type': 'application/json',
        };

        fetch(locationsURL, { method: 'GET', headers, mode: 'cors' })
            .then((response) => {
                return response.json();
            })
            .then((locationsResponse) => {
                fetch(objectsURL, { method: 'GET', headers, mode: 'cors' })
                    .then((response) => response.json())
                    .then((objectsResponse) => {
                        fetch(objSetsURL, { method: 'GET', headers, mode: 'cors' })
                            .then((response) => response.json())
                            .then((objSetsResponse) => {
                                return { locationsResponse, objectsResponse, objSetsResponse };
                            })
                            .then((result) => {
                                dispatch(
                                    runAction('setLocation', {
                                        json: result.locationsResponse,
                                    })
                                );
                                return result;
                            })
                            .then((result) => {
                                dispatch(
                                    runAction('getObjectsAndLocationsAndObjSets', {
                                        ...result,
                                    })
                                );
                            });
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAvailableOwners = (token) => {
        var url = `${hostForLocation.getHostName().admin}/analytics_structure/available_owners/`;
        var headers = {
            'x-token': token,
        };
        dispatch(runAction('getAvailableOwners', { url, headers }));
    };
    const getAvailableProjects = (token) => {
        var url = `${hostForLocation.getHostName().admin}/analytics_structure/available_projects/`;
        var headers = {
            'x-token': token,
        };
        dispatch(runAction('getAvailableProjects', { url, headers }));
    };
    const getOutdoorAdSpaces = (token) => {
        const headers = {
            'x-token': token,
        };
        const url = `${hostForLocation.getHostName().admin}/adapp/outdoor_ad_spaces/`;
        dispatch(runAction('getOutdoorAdSpaces', { url, headers }));
    };
    const getAdSpacesTypes = (token) => {
        const headers = {
            'x-token': token,
        };
        const url = `${hostForLocation.getHostName().admin}/adapp/ad_space_types/`;
        dispatch(runAction('getAdSpacesTypes', { url, headers }));
    };
    const getPolygonGroups = (token) => {
        const headers = {
            'x-token': token,
        };
        const url = `${hostForLocation.getHostName().outdoorMapsGetShopster}/regions/region_groups/`;
        dispatch(runAction('getPolygonGroups', { url, headers }));
    };
    const fullScreenExit = () => {
        dispatch(runAction('fullScreenExit'));
    };
    const getRequestReportings = (data) => {
        dispatch(runAction('getReportingObjects', { ...data }));
    };
    const getStatusSelectPolygon = (status) => {
        dispatch(runAction('getStatusSelectPolygon_MapsReducer', { status: status }));
        dispatch(runAction('getStatusShowMetricsPanel_MapsReducer', { status: status }));
    };
    const dismissAlert = () => {
        dispatch(runAction('toggleSharedAlert', { show: false }));
    };
    const toggleOverlaySpinner = (show) => {
        dispatch(runAction('toggleOverlaySpinner', { show }));
    };
    const toggleSharedAlert = (args) => {
        dispatch(runAction('toggleSharedAlert', { ...args }));
    };
    const fillBackend = (backend) => {
        dispatch(runAction('fillBackend_EngineeringMenu', { backend }));
        toggleModal({ show: false });
    };
    const closeLogOverlay = () => {
        dispatch(runAction('setLogOverlayShow_Diagrams', { args: null }));
    };
    const hideLogoutManu = (e) => {
        e.stopPropagation();
        dispatch(runAction('onDropDownLogout', { status: false }));
    };
    const hideSettingsPanel = (e) => {
        e.stopPropagation();
        dispatch(runAction('hideSettingsPanel'));
    };

    const getStatusSettingsLoad = (status) => {
        dispatch(runAction('isLoadSettingsRequest', {status: status}));
    }

    const createDataObjSet = (token, name, objects) => {
        var url = `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/`;
        var headers = {
            'x-token': token,
        };
        const data = {
            name,
            read_access_type: 'creator_only',
            bound_dataobjs: objects,
        };

        const options = {
            method: 'POST',
            headers: { 'Content-type': 'application/json', 'x-token': token },
            body: JSON.stringify(data),
            credentials: 'include',
        };

        fetch(url, options)
            .then((response) => dispatch(runAction('getDataObjSets', { url, headers })))
            .then(() => dispatch(runAction('toggleModal', { args: { show: false } })));
    };

    const createNewAdCampaign = (token, args) => {
        toggleOverlaySpinner(true);

        let url = '';
        const headers = {
            'x-token': token,
        };
        const formData = new FormData();

        formData.append('name', args.name);
        formData.append('mailru_needs_upload', args.mailru_needs_upload);
        formData.append('description', args.description);
        formData.append('mailru_accounts', JSON.stringify(args.mailru_accounts.filter((item) => item !== '')));
        formData.append('yandex_needs_upload', args.yandex_needs_upload);
        formData.append('yandex_accounts', JSON.stringify(args.yandex_accounts.filter((item) => item !== '')));
        formData.append('mailru_is_public', args.mailru_is_public);

        switch (args.type) {
            case 'static':
                url = `${hostForLocation.getHostName().export}/adapp/campaigns/create_with_ad_space_ids/`;
                formData.append('ad_space_ids', JSON.stringify(args.ad_space_ids));
                formData.append('date_from', moment(args.date_from).format('YYYY-MM-DD'));
                formData.append('date_to', moment(args.date_to).format('YYYY-MM-DD'));
                break;
            case 'dynamic':
                url = `${hostForLocation.getHostName().export}/adapp/campaigns/create_with_roll_table/`;
                formData.append('file', args.file);
                break;

            default:
                break;
        }

        const options = {
            method: 'POST',
            headers,
            body: formData,
            credentials: 'include',
        };

        fetch(url, options)
            .then((res) => {
                if (res.ok) {
                    return res;
                } else {
                    return Promise.reject(res);
                }
            })
            .then((response) => getAdCampaigns(token, dispatch, campaignsToShow, project_id))
            .then(() => {
                toggleOverlaySpinner(false);
                toggleModal({ show: false });
            })
            .then(() =>
                toggleSharedAlert({ show: true, position: 'bottom', type: 'success', message: t('Новая рекламная кампания создана') })
            )
            .catch((err) => {
                toggleOverlaySpinner(false);
                toggleSharedAlert({
                    show: true,
                    position: 'bottom',
                    type: 'error',
                    message: t('Возникла ошибка при создании рекламной кампании'),
                });
            });
        // .then(() => dispatch(runAction('toggleModal', { args: { show: false } })));
    };

    const createNewSegment = (token, args) => {
        toggleOverlaySpinner(true);

        const url = `${hostForLocation.getHostName().export}${args.url}`;
        const headers = {
            'x-token': token,
        };
        const formData = new FormData();

        formData.append('name', args.name);
        formData.append('description', args.description);
        formData.append('mailru_needs_upload', args.mailru_needs_upload);
        formData.append('mailru_accounts', JSON.stringify(args.mailru_accounts.filter((item) => item !== '')));
        formData.append('yandex_needs_upload', args.yandex_needs_upload);
        formData.append('yandex_accounts', JSON.stringify(args.yandex_accounts.filter((item) => item !== '')));
        formData.append('date_from', moment(args.date_from).format('YYYY-MM-DD'));
        formData.append('date_to', moment(args.date_to).format('YYYY-MM-DD'));
        formData.append('mailru_is_public', args.mailru_is_public);

        switch (args.mode) {
            case 'from_file':
                formData.append('file', args.file);
                formData.append('content_type', args.content_type);
                break;
            case 'from_obj_ids':
                formData.append('obj_ids', JSON.stringify(args.obj_ids));
                formData.append('client_type', args.client_type);
                if (args.id) {
                    formData.append('id', Number(args.id));
                }
                break;
            default:
                break;
        }

        const options = {
            method: 'POST',
            headers,
            body: formData,
            credentials: 'include',
        };

        fetch(url, options)
            .then((res) => {
                if (res.ok) {
                    return res;
                } else {
                    return Promise.reject(res);
                }
            })
            .then(() => getSegments(token, dispatch, segmentsToShow))
            .then(() => {
                toggleOverlaySpinner(false);
                toggleModal({ show: false });
            })
            .then(() => toggleSharedAlert({ show: true, position: 'bottom', type: 'success', message: t('Новый сегмент создан') }))
            .catch((err) => {
                toggleOverlaySpinner(false);
                toggleSharedAlert({
                    show: true,
                    position: 'bottom',
                    type: 'error',
                    message: t('Возникла ошибка при создании сегмента'),
                });
            });
        // .then(() => dispatch(runAction('toggleModal', { args: { show: false } })));
    };

    const onChangeObjSet = (id, name, objects, token) => {
        var url = `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/${id}/`;
        var headers = {
            'x-token': token,
        };
        const data = {
            name,
            read_access_type: 'creator_only',
            bound_dataobjs: objects,
        };

        const options = {
            method: 'PATCH',
            headers: { 'Content-type': 'application/json', 'x-token': token },
            body: JSON.stringify(data),
            credentials: 'include',
        };

        fetch(url, options).then((response) =>
            dispatch(
                runAction('getDataObjSets', {
                    url: `${hostForLocation.getHostName().admin}/analytics_structure/dataobjsets/`,
                    headers,
                })
            )
        );
    };
    

    // ------------ END of DISPATCH ------------


    return (
        <React.Fragment>
            
            {allCatalogsReady ? (
                <div id={'react'} className={'ReactAppBox'} onClick={_onClickGlobalHolstForMapsHideAnaliticsPanel}>
                    <Cloud />
                    {isCloudDataReceived && isPermanentCloudDataReceived ? (
                        <React.Fragment>
                            <Alert {...sharedAlert} dismissAlert={dismissAlert} />

                            {cabinetMode === '' ? (
                                <BoxTopPanel
                                    hideLogoutManu={hideLogoutManu}
                                    cookie={cookie}
                                    lang={lang === 'ru' ? 'ru-RU' : 'en-EN'}
                                    _t={i18n}
                                    selected={selected}
                                    history={history}
                                />
                            ) : null}

                            {cabinetMode === '' ? <BoxLeftPanel cabinetMode={cabinetMode} lang={lang} _t={i18n} /> : null}

                            {cabinetMode === '' ? (
                                <MainContent cabinetMode={cabinetMode} lang={lang} _t={i18n} />
                            ) : cabinetMode === '-pdf' ? (
                                <PdfMainContent lang={lang} />
                            ) : null}

                            <DiagramParamsPanel hideSettingsPanel={hideSettingsPanel} lang={lang} />

                            {paramsSet.custom.includes('outdoor_ad_space_ids') && cabinetMode !== '-pdf' ? (
                                <OutdoorSpaces show={isMapExpanded} />
                            ) : null}

                            {showAdSpacesMapPanel ? <OutdoorSpaces show={true} mode={'staticAdCamp'} /> : null}

                            {paramsSet.custom.includes('ad_campaign_id') && cabinetMode !== '-pdf' ? <AdCampaigns /> : null}

                            {cabinetMode !== '-pdf' && isShowPrecampModal ? <ModalPre/> : null}

                            {modal.show ? (
                                <Modal modalParams={modal} toggleModal={toggleModal} onModalButtonClick={_onModalButtonClick} />
                            ) : null}
                            {showOverlaySpinner ? <OverlaySpinner /> : null}
                            {isCompleteTablesShown ? <CompleteTablesPanel _t={i18n} /> : null}
                            {statusShowPlanPanel ? <MainPlan _t={i18n} /> : null}
                            {statusShowSettingsPanel ? <MainSettingsControlPanel
                                getStatusSettingsLoad={getStatusSettingsLoad}
                                _t={i18n}
                            /> : null}
                            {logOverlay.show ? (
                                <LogOverlay
                                    {...logOverlay.args}
                                    closeLogOverlay={closeLogOverlay}
                                    onClickReloadDiagram={onClickReloadDiagram}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : (
                        <SpinnerContainer>
                            <ClipLoader color={'#46b875'} loading={true} size={30} />
                            {t('Данные формируются...')}
                        </SpinnerContainer>
                    )}
                </div>
            ) : (
                <SpinnerContainer>
                    <ClipLoader color={'#46b875'} loading={true} size={30} />
                    {t('Данные формируются...')}
                </SpinnerContainer>
            )}
            {isShowModalPdf ? <MainPdf/> : null}
            { isShowSpinerPlan ? <SpinnerContainer2>
                <SpinnerContainerTemp>    
                    <ClipLoader color={'#46b875'} loading={true} size={30} />
                </SpinnerContainerTemp>
            
            </SpinnerContainer2> : null}
            { isLoadData ? <SpinnerContainer2>
                <SpinnerContainerTemp>    
                    <ClipLoader color={'#46b875'} loading={true} size={30} />
                </SpinnerContainerTemp>
            
            </SpinnerContainer2> : null}
        </React.Fragment>
    );
});

ReactDOM.render(
    <Provider store={store}>
        <Main />
    </Provider>,
    document.getElementById('app')
);
